import activity from './m-documentation.vue';

export default activity;

export const componentRegistrationData = {
  name: 'Documentation',
  icon: 'mdi-file-document-outline',
  description: 'Document a technical prodouct',
  status: true,
  setupRequired: false,
  styling: {
    borderColor: '#b38f6b'
  },
  fields: [
    {
      roles: ['employer', 'student'],
      name: 'm-documentation',
      type: 'molecule',
      position: 0,
      preset: {},
      setup: {
        docDesc: ''
      },
      value: {
        docLink: ''
      }
    },
    {
      roles: ['employer, participant'],
      name: 'm-save-adk-only',
      type: 'molecule',
      position: 1,
      preset: {},
      setup: {},
      value: {}
    }
  ]
};
